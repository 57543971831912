import { useEffect } from "react";
import Typed from "typed.js";

export const Typd = () => {
  useEffect(() => {
    const typed = new Typed("#introHeadline", {
      strings: [
        "Stoyan",
        "a developer",
        "a kitesurfer",
        "into boardgames",
        "into swimming",
      ],
      loop: true,
      typeSpeed: 75,
      backSpeed: 100,
      backDelay: 2000,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return <span id="introHeadline" />;
};
