import React, { useEffect } from "react";
import part1 from "../../assets/parallax/1.png";
import part2 from "../../assets/parallax/2.png";
import part3 from "../../assets/parallax/3.png";
import part4 from "../../assets/parallax/4.png";

import Parallax from "parallax-js";

const ParallaxBackground = () => {
  useEffect(() => {
    const scene: any = document.getElementById("scene");
    new Parallax(scene, {
      relativeInput: true,
    });
  });

  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="parallaxContainer">
          <div id="scene">
            <div className="">
              <img alt="" src={part1}></img>
            </div>
            <div className="">
              <img alt="" src={part2}></img>
            </div>
            <div className="" data-depth-x="-0.05">
              <img alt="" src={part3}></img>
            </div>
            <div className="" data-depth-x="0.05">
              <img alt="" src={part4}></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParallaxBackground;
