import React from "react";
import "./header.scss";
import { ReactComponent as LinkedinIcon } from "../assets/icons/logo-linkedin.svg";
import { ReactComponent as FacebookIcon } from "../assets/icons/logo-facebook.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/logo-instagram.svg";
import { ReactComponent as GithubIcon } from "../assets/icons/logo-github.svg";

const Header = () => {
  return (
    <>
      <div
        className="header border-bottom-black"
        style={{
          boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="col-12 d-flex justify-content-between">
          <div className="col-4 d-flex justify-content-end">
            <p className="mt-2 fs-5 fw-bold">Stoyan</p>
          </div>
          <div
            className="col-4 d-flex justify-content-start"
            style={{ opacity: 0.8 }}
          >
            <div className="mt-2 px-1">
              <LinkedinIcon width={30}></LinkedinIcon>
            </div>
            <div className="mt-2 px-1">
              <GithubIcon width={30}></GithubIcon>
            </div>
            <div className="mt-2 px-1">
              <InstagramIcon width={30}></InstagramIcon>
            </div>
            <div className="mt-2 px-1">
              <FacebookIcon width={30}></FacebookIcon>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
