import React, { useEffect } from "react";
import Header from "./header/Header";
import Intro from "./intro/Intro";
import "./intro/intro.scss";
import gsap from "gsap";
import "./App.scss";
import { Typd } from "./components/typewriter/Typd";
import Lottie from "lottie-react";
import animationData from "./assets/gifs/82423-developer-yoga.json";
import animationData2 from "./assets/gifs/143124-team.json";
import ParallaxBackground from "./components/parallax/ParallaxBackground";
import { Element, scroller } from "react-scroll";
import Aos from "aos";
import "aos/dist/aos.css";

const App = () => {
  const scrollToNextElement = () => {
    scroller.scrollTo("section2", {
      duration: 400,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -200,
    });
  };

  useEffect(() => {
    Aos.init({ duration: 1250 });

    const powerOut = gsap.timeline({ defaults: { ease: "power1.out" } });

    powerOut.to(".slider", { y: "-100%", duration: 1.5, delay: 1 });
    powerOut.to(".intro", { y: "-100%", duration: 1 }, "-=1");
    powerOut.fromTo(".head", { opacity: 0 }, { opacity: 1, duration: 1.25 });
  }, []);

  return (
    <>
      <Intro />
      <div style={{ height: "110vh" }} className="head">
        <div className="">
          <ParallaxBackground />
        </div>
        <div
          className="container d-flex flex-column align-items-center justify-content-center"
          style={{
            overflow: "hidden",
            display: "inline-block",
          }}
        >
          <div className="col-12 w-100 p-5">
            <Header />
          </div>
          <div className="content col-12 w-75 py-5">
            <div className="row">
              <div className="col-4">
                <h3>Hi</h3>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-10">
                    <h2>
                      I am <Typd />
                    </h2>
                  </div>
                  <div
                    className="col-5 d-flex justify-content-end"
                    style={{ position: "absolute", bottom: 1, left: 725 }}
                  >
                    <Lottie animationData={animationData} />
                  </div>
                </div>
              </div>

              <div className="col-8">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptatem esse earum delectus deleniti saepe suscipit vel
                  fugiat voluptatum iure. Nobis tempore ad impedit distinctio
                  quasi ab cupiditate obcaecati laboriosam eius?
                </p>
              </div>
              <button
                className="col-11 d-flex justify-content-center h-100 d-inline-block mt-5"
                onClick={scrollToNextElement}
              >
                Click me
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="hide" data-aos="fade-up">
        <Element name="section2" />
        <div
          className="container d-flex flex-column align-items-center justify-content-center"
          style={{
            display: "inline-block",
          }}
        >
          <div className="content col-12 w-75 py-5 mt-5">
            <div className="row">
              <div className="col-12">
                <h3>Projects I have worked on</h3>
              </div>
              <div className="col-12">
                <div className="row">
                  <div
                    className="col-6 d-flex justify-content-end"
                    style={{ position: "absolute", bottom: -100, left: 675 }}
                    data-aos="fade-up"
                  >
                    <Lottie animationData={animationData2} />
                  </div>
                </div>
              </div>

              <div className="col-8">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptatem esse earum delectus deleniti saepe suscipit vel
                  fugiat voluptatum iure. Nobis tempore ad impedit distinctio
                  quasi ab cupiditate obcaecati laboriosam eius?
                </p>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="col-4 boxes">1</div>
            <div className="col-4 boxes">2</div>
            <div className="col-4 boxes">3</div>
            <div className="col-4 boxes">4</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
