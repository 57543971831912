import React from "react";
import "./intro.scss";

const Intro = () => {
  return (
    <>
      <div className="intro" />
      <div className="slider" />
    </>
  );
};

export default Intro;
